import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Website Delivery Scope" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE DELIVERY <br/>
<span> SCOPE</span></h2>
                                <p></p>
                                <div className="horizonral-subtitle"><span>SCOPE</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>SCOPE</div>
                        <div className="container main-about">
                          

<p style={{fontSize: '13px' }}>PragICTS provides the following scope for the design and implementation of websites.</p><br/>
                            
                            <h2>Default</h2>
                           <p> <h6 style={{fontSize: '13px'}}>1. Bespoke website design approach and implementation. Non WordPress based.</h6>
                            <h6 style={{fontSize: '13px'}}>2. Engineered for performance and security</h6>
                            <h6 style={{fontSize: '13px'}}>3. Target Audience/Domain Segmentation</h6>
                            <h6 style={{fontSize: '13px'}}>4. Fully Responsive design and implementation across IT-enabled devices (mobile, tabs, laptops, desktops, etc.)</h6>
                            <h6 style={{fontSize: '13px'}}>5. On-Page (Website) SEO (Title, Keywords, Description, and Image Tagging)</h6>
                            <h6 style={{fontSize: '13px'}}>6. Open Graph implementation for content sharing</h6>
                            <h6 style={{fontSize: '13px'}}>7. Google Analytics Integration</h6>
                            <h6 style={{fontSize: '13px'}}>8. Site Map XML submission</h6>
                            <h6 style={{fontSize: '13px'}}>9. CDN (Content Delivery Network) integration for fast access</h6>
                            <h6 style={{fontSize: '13px'}}>10. SSL implementationn</h6>
                            <h6 style={{fontSize: '13px'}}>11. Social Platform Integration</h6>  </p>
<div>










</div> 


<h2>Optional</h2>

<p> <h6 style={{fontSize: '13px'}}>1. Content Authoring</h6>
                            <h6 style={{fontSize: '13px'}}>2. Branding</h6>
                            <h6 style={{fontSize: '13px'}}>3. Social Platform implementation and setup</h6>
                            <h6 style={{fontSize: '13px'}}>4. Tier 1 Hosting on AWS or Microsoft Azure</h6>
                            <h6 style={{fontSize: '13px'}}>5. Domain Name purchase and configuration</h6>
                            <h6 style={{fontSize: '13px'}}>6. Image Purchase</h6>
                            <h6 style={{fontSize: '13px'}}>7. Photo Shoot</h6>
                            <h6 style={{fontSize: '13px'}}>8. Drone Short</h6>
                            <h6 style={{fontSize: '13px'}}>9. Video Compilation</h6>
                            <h6 style={{fontSize: '13px'}}>10. Google Business Registration</h6>
                            <h6 style={{fontSize: '13px'}}>11. Content management module</h6>  </p>



















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
